import React, { Fragment, useState, useRef, useEffect } from "react";
import {
  Container,
  TableWrapper,
  HyperLink,
  StyledAmount,
  TopCenter,
  Center,
  DeleteButton,
} from "../PaymentHistory/Styles";
import _ from "lodash";
import queryString from "query-string";
import CustomTable from "../../common/CustomTable/CustomTable";
import CaseService from "../../../services/CaseService";
import moment from "moment";
import {
  getErrorMessage,
  getFileName,
  parseTimeStamp,
  numberFormat,
} from "../../../helpers/functions";
import { useSnackbar } from "notistack";
import labels from "../../../helpers/labels.json";
import { ActionButton } from "../../common/Buttons";
import CartService from "../../../services/CartService";
import useLoader from "../../../hooks/useLoader";
import useAlert from "../../../hooks/useAlert";
import styled from "styled-components";
import { navigate } from "@reach/router";
import PaymentModal from "./PaymentModal";
import useNotification from "../../../hooks/useNotification";

async function getOrders(query = "") {
  try {
    const response = await CaseService.getOrders(query);
    return { ...response };
  } catch (error) {
    throw error;
  }
}

const ProfomaHistory = () => {
  const [state, setState] = useState({});
  const MTRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const { setLoader } = useLoader();
  const placeholderText = "Search Invoice";
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const { showAlert, hideAlert } = useAlert();
  const [modal, setModal] = useState({ state: false });
  const { transaction } = useNotification();

  useEffect(() => {
    if (triggerRefresh) {
      MTRef.current.onQueryChange(MTRef?.current?.state?.query);
      setTriggerRefresh(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerRefresh]);

  useEffect(() => {
    if (transaction) {
      capturePayment(transaction);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transaction]);

  const columns = [
    {
      field: "caseId",
      title: labels["table.case_id"],
      sorting: false,
      render: (rowData) =>
        rowData?.caseId === "Multiple" ? (
          <HyperLink>
            <TopCenter>{rowData?.caseId}</TopCenter>
          </HyperLink>
        ) : (
          <HyperLink
            style={{ cursor: "pointer" }}
            onClick={() =>
              navigate(
                `/dashboard/cases/${rowData?.caseId}?caseType=${rowData?.resolutionKind}`,
              )
            }
          >
            <TopCenter>{rowData?.caseId}</TopCenter>
          </HyperLink>
        ),
      headerStyle: {
        maxWidth: 90,
        width: 90,
        padding: `12px 16px`,
      },
      cellStyle: {
        maxWidth: 90,
        width: 90,
        padding: `12px 16px`,
      },
    },
    {
      field: "id",
      title: labels["table.order_id"],
      tooltip: "Payment order identifier",
      sorting: true,
      render: (rowData) => (
        <HyperLink>
          <TopCenter>{rowData?.id}</TopCenter>
        </HyperLink>
      ),
      headerStyle: {
        maxWidth: 100,
        width: 100,
        padding: `12px 16px`,
      },
      cellStyle: {
        paddingLeft: 32,
      },
    },
    {
      field: "id",
      title: labels["table.profoma_invoice_id"],
      sorting: true,
      render: (rowData) => (
        <HyperLink
          style={{
            textDecoration: "none",
            cursor: "inherit",
            marginLeft: 13,
          }}
        >
          {rowData?.id}
        </HyperLink>
      ),
      cellStyle: {
        paddingLeft: 32,
      },
    },
    {
      field: "parties.name",
      title: labels["table.party_name"],
      sorting: false,
      render: (rowData) => rowData?.party?.name,
      headerStyle: {
        maxWidth: 100,
        width: 100,
        padding: `12px 16px`,
      },
      cellStyle: {
        maxWidth: 100,
        width: 100,
        padding: `12px 16px`,
      },
    },
    {
      field: "amount",
      title: labels["table.amount"],
      sorting: false,
      render: (rowData) => (
        // <StyledAmount>INR {rowData?.amount?.toFixed(2)}</StyledAmount>
        <StyledAmount>
          {numberFormat(
            parseFloat(rowData?.amount).toFixed(2),
            rowData?.cartItems[0]?.fee?.unit,
          )}
        </StyledAmount>
      ),
    },
    {
      field: "created_at",
      title: labels["table.date"],
      tooltip: "Date of transaction",
      sorting: true,
      render: (rowData) => (
        <>{moment(parseTimeStamp(rowData?.created_at)).format("DD/MM/YYYY")}</>
      ),
    },
    {
      field: "invoiceUrl",
      title: labels["table.proforma_invoice"],
      tooltip: "Proforma invoice of the transaction ",
      sorting: false,

      render: (rowData) =>
        rowData?.proformaInvoiceUrl ? (
          <HyperLink
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={() => window.open(rowData?.proformaInvoiceUrl)}
          >
            {rowData?.proformaInvoiceUrl
              ? getFileName(rowData?.proformaInvoiceUrl)?.split(".pdf")[0]
              : "Download"}
          </HyperLink>
        ) : (
          <Center>-</Center>
        ),
    },
    {
      field: "action",
      title: labels["table.action"],
      sorting: false,
      render: (rowData) => (
        <Buttons>
          <PayButton>
            <ActionButton
              style={{
                margin: 0,
                height: 30,
                width: 90,
                fontSize: 10,
                marginRight: "10px",
              }}
              onClick={() => openBillDeskPayment(rowData)}
              // onClick={() => handlePaynow(rowData)}
            >
              {labels["table.pay_now"]}
            </ActionButton>
            <ActionButton
              style={{ margin: 0, height: 30, width: 90, fontSize: 10 }}
              onClick={() => setModal({ state: true, data: rowData })}
            >
              {"Pay offline"}
            </ActionButton>
          </PayButton>
          <DeleteButton>
            <HyperLink
              isError
              onClick={() => deleteProformaConfirmation(rowData)}
            >
              {labels["table.delete"]}
            </HyperLink>
          </DeleteButton>
        </Buttons>
      ),
      headerStyle: {
        width: "150px !important",
      },
    },
  ];

  const deleteProformaConfirmation = (rowData) => {
    showAlert({
      heading: "Delete Confirmation",
      desc: `Are you sure you want to delete this Proforma invoice?`,
      primaryBtnText: "OK",
      secondaryBtnText: "Cancel",
      clickSecondarybtn: () => hideAlert(),
      clickPrimaryBtn: () => {
        deleteInvoice(rowData);
        hideAlert();
      },
      isOpen: true,
      descriptionTextStyle: {
        marginBottom: 30,
      },
      onDialogClose: () => hideAlert(),
    });
  };

  const deleteInvoice = async ({ id }) => {
    try {
      setLoader({ state: true, message: "deleting the Proforma invoice..." });
      const result = await CaseService.deleteProforma(id);
      if (result?.message) {
        enqueueSnackbar(result?.message, {
          variant: "success",
        });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      setTriggerRefresh(true);
    }
  };

  // const handlePaynow = async (rowData) => {
  //   try {
  //     const payload = {
  //       paymentId: rowData?.id,
  //     };
  //     setLoader({ state: true, message: "Initiating Payment..." });
  //     const res = await CartService.payPerformaInvoice(payload);
  //     if (res) {
  //       setLoader({ state: false });
  //       if (res?.status === "capturedOffline" || res?.status === "captured") {
  //         enqueueSnackbar("Payment has already made for this invoice", {
  //           variant: "error",
  //         });
  //         setTriggerRefresh(true);
  //       } else {
  //         openBillDesk();
  //         // openRazorPay({
  //         //   order_id: res.orderId,
  //         //   name: userObject?.name,
  //         //   email: userObject?.email,
  //         //   contact: userObject?.mobile,
  //         //   handler: capturePayment,
  //         // });
  //       }
  //     }
  //   } catch (error) {
  //     const message = getErrorMessage(error);
  //     enqueueSnackbar(message, {
  //       variant: "error",
  //     });
  //   } finally {
  //     setLoader({ state: false });
  //   }
  // };

  const openBillDeskPayment = async (rowData) => {
    try {
      const payload = {
        paymentId: rowData?.id,
      };
      const res = await CartService.payPerformaInvoice(payload);
      const bdOrderId = res?.bdorderid; // Replace with your actual bdOrderId
      const merchantId = res?.mercid; // Replace with your actual merchantId
      const rdata = res?.rdata; // Replace with your actual rdata

      // Construct the URL for BillDesk API
      const billDeskURL = `https://pay.billdesk.com/web/v1_2/embeddedsdk?bdOrderId=${bdOrderId}&merchantId=${merchantId}&rdata=${encodeURIComponent(
        rdata,
      )}`;

      // Open the BillDesk payment gateway in the same window
      window.location.href = billDeskURL;
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    }
  };

  // const handlePaynow = async (e) => {
  //   e.preventDefault();

  //   const headers = new Headers();
  //   headers.append("Content-Type", "application/json");
  //   headers.append(
  //     "authorization",
  //     "OToken 10096a32f3deec6dc2b4185da68694600b25f1dc12e32984d623e40eab4d578e96d17f381aaddae7349415a2dc6a65861ead706144c04156ee52bc48adfae412eafffc2a32f644cf65648ef4987268a998e356596387747951be2e5d5e775b9b35e04ab64ae54596408b7b84ac7d9f823f30d9817f0633518f156ba9db0dbe8a7ff754095390d93dbeb56807b3061103308251dff7541fcd51.4145535f55415431",
  //   );

  //   let formData = {
  //     bdOrderId: "OAO419XTV9B3BW",
  //     merchantId: "UATGPTV2",
  //     rdata:
  //       "8c033f99b5cce83e192d6d124227a3cb9c92147e6c2b4a79bb06d3fc323d22572ff735a8284055fc716c294ed1b681c44a06bb576c94affa2923290e53d3c5.7561746b657931",
  //   };
  //   const requestOptions = {
  //     method: "POST",
  //     headers: headers,
  //     body: JSON.stringify(formData),
  //   };

  //   try {
  //     const response = await fetch(
  //       "https://uat1.billdesk.com/u2/web/v1_2/embeddedsdk",
  //       requestOptions,
  //     );
  //     const html = await response.text(); // Capture the HTML response
  //     const newWindow = window.open();
  //     newWindow.document.open();
  //     newWindow.document.write(html);
  //     newWindow.document.close();
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // };

  /**
   * @description capture payment
   * @param {*} rzp_res
   */

  async function capturePayment(rzp_res) {
    document.body.style.overflow = null;
    try {
      setLoader({ state: true, message: "Capturing Payment..." });
      const capture_res = await CartService.capture(rzp_res);
      if (capture_res) {
        enqueueSnackbar(capture_res.message, {
          variant: "success",
        });
        setTriggerRefresh(true);
        // setTriggerCount(true);
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  }

  const data = (query) =>
    new Promise((resolve) => {
      let params = {
        page: query.page + 1,
        perPage: 10,
        filter: "status",
      };
      if (query.search) {
        params.query = query.search;
      }
      if (query.orderBy?.field) {
        params.sort = query.orderBy.field;
        params.sortDirection = query.orderDirection;
      }
      let stringParams = "";
      const filterSting = queryString.stringify(
        { filterValues: ["proformaGenerated"] },
        { arrayFormat: "index" },
      );
      if (!_.isEmpty(params)) {
        stringParams = `?${queryString.stringify(params)}&${filterSting}`;
      }
      getOrders(stringParams)
        .then(async (result) => {
          if (result) {
            resolve({
              data: result.data,
              page: result.page - 1,
              total: result.total,
            });
            setState(result);
          }
        })
        .catch((error) => {
          enqueueSnackbar(getErrorMessage(error), {
            variant: "error",
          });
          setState({ data: [], lastPage: 1, page: 1, perPage: 10, total: 0 });
          resolve({
            data: [],
            page: 0,
            total: 0,
          });
        });
    });

  return (
    <Fragment>
      <Container className="CreateCase" style={{ overflow: "hidden" }}>
        <TableWrapper>
          <CustomTable
            hidePagination={state.lastPage === 1}
            pageSize={state?.data?.length ? state?.data?.length : 10}
            pluralTitle={labels.proforma_invoice}
            singularTitle=""
            {...{
              columns,
              data,
              placeholderText,
              MTRef,
            }}
            //noToolbar
            hideFilterBar
            state={state}
          />
          {modal?.state && <PaymentModal {...{ modal, setModal }} />}
        </TableWrapper>
      </Container>
    </Fragment>
  );
};

export default ProfomaHistory;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;

  @media (min-width: 780px) {
    flex-direction: column;
    align-items: start;
  }
`;

const PayButton = styled.div`
  display: flex;
  margin-left: 25px;
  @media (min-width: 780px) {
    display: flex;
    margin-left: 0px;
  }
`;
